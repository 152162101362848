import React, { useState, useEffect } from "react";
import TaskForm from "./TaskForm";
import EditTaskForm from "./EditTaskForm";
import "./App.css";

const App = () => {
  const [tasks, setTasks] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const [isFormVisible, setIsFormVisible] = useState(false); // Controlar visibilidad del formulario
  const [editingTask, setEditingTask] = useState(null);
  const [editingDay, setEditingDay] = useState(null);
  const [filter, setFilter] = useState("all"); // Filtro de tareas

  useEffect(() => {
    const savedTasks = localStorage.getItem("tasks");
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  const addTask = (day, task) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [day]: [...prevTasks[day], task],
    }));
  };

  const toggleTaskCompletion = (day, index) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [day]: prevTasks[day].map((task, i) =>
        i === index ? { ...task, completed: !task.completed } : task
      ),
    }));
  };

  const deleteTask = (day, index) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [day]: prevTasks[day].filter((_, i) => i !== index),
    }));
  };

  const editTask = (day, index) => {
    setEditingTask(tasks[day][index]);
    setEditingDay(day);
  };

  const updateTask = (day, index, updatedTask) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [day]: prevTasks[day].map((task, i) =>
        i === index ? updatedTask : task
      ),
    }));
    setEditingTask(null);
    setEditingDay(null);
  };

  // Reintegrar las notificaciones
  useEffect(() => {
    const checkTaskDeadline = () => {
      const now = new Date();
      Object.keys(tasks).forEach((day) => {
        tasks[day].forEach((task) => {
          const taskTime = new Date(`1970-01-01T${task.time}:00`);
          const timeDifference = taskTime - now;
          if (!task.completed && timeDifference < 60000 && timeDifference > 0) {
            if (Notification.permission === "granted") {
              new Notification(`Reminder: "${task.name}" is due soon!`);
            }
          }
        });
      });
    };
    const interval = setInterval(checkTaskDeadline, 60000); // Verificar cada minuto
    return () => clearInterval(interval);
  }, [tasks]);

  const filteredTasks = (day) => {
    return tasks[day].filter((task) => {
      if (filter === "completed") return task.completed;
      if (filter === "pending") return !task.completed;
      return true;
    });
  };

  const resetTasks = () => {
    setTasks({
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    });
  };

  return (
    <div className="App">
      <h1>LetiTask - Weekly Task Manager</h1>

      {/* Botón para mostrar/ocultar el formulario */}
      <button
        onClick={() => setIsFormVisible(!isFormVisible)}
        className="add-task-btn"
      >
        {isFormVisible ? "Hide Form" : "Add Task"}
      </button>

      {/* Mostrar formulario solo si isFormVisible es true */}
      {isFormVisible && <TaskForm addTask={addTask} />}

      {editingTask && (
        <EditTaskForm
          task={editingTask}
          day={editingDay}
          updateTask={updateTask}
        />
      )}

      {/* Botones de filtro */}
      <div className="filter-buttons">
        <button onClick={() => setFilter("all")}>All</button>
        <button onClick={() => setFilter("completed")}>Completed</button>
        <button onClick={() => setFilter("pending")}>Pending</button>
        <button onClick={resetTasks}>Reset All</button>
      </div>

      <div className="task-list">
        {Object.keys(tasks).map((day) => (
          <div key={day} className="day-section">
            <h2>{day}</h2>
            {filteredTasks(day).length > 0 ? (
              <ul>
                {filteredTasks(day).map((task, index) => (
                  <li
                    key={index}
                    className={`task-item ${task.completed ? "completed" : ""}`}
                  >
                    <input
                      type="checkbox"
                      checked={task.completed}
                      onChange={() => toggleTaskCompletion(day, index)}
                    />
                    <span className="task-name">
                      {task.name} - {task.priority} - {task.time}
                    </span>
                    <div className="task-actions">
                      <button onClick={() => editTask(day, index)}>Edit</button>
                      <button onClick={() => deleteTask(day, index)}>
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No tasks for this day</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
