import React, { useState } from "react";

const EditTaskForm = ({ task, day, updateTask }) => {
  const [taskName, setTaskName] = useState(task.name);
  const [taskPriority, setTaskPriority] = useState(task.priority);
  const [taskTime, setTaskTime] = useState(task.time);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedTask = {
      ...task,
      name: taskName,
      priority: taskPriority,
      time: taskTime,
    };
    updateTask(day, task.index, updatedTask);
  };

  return (
    <form onSubmit={handleSubmit} className="task-form vertical-form">
      <h3>Edit Task</h3>
      <label>
        Task Name:
        <input
          type="text"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          required
        />
      </label>

      <label>
        Priority:
        <select value={taskPriority} onChange={(e) => setTaskPriority(e.target.value)} required>
          <option value="Low">Low</option>
          <option value="Normal">Normal</option>
          <option value="High">High</option>
        </select>
      </label>

      <label>
        Time:
        <input
          type="time"
          value={taskTime}
          onChange={(e) => setTaskTime(e.target.value)}
          required
        />
      </label>

      <button type="submit" className="submit-btn">Update Task</button>
    </form>
  );
};

export default EditTaskForm;
