import React, { useState } from "react";

const TaskForm = ({ addTask }) => {
  const [taskName, setTaskName] = useState("");
  const [taskPriority, setTaskPriority] = useState("Normal");
  const [day, setDay] = useState("Monday");
  const [taskTime, setTaskTime] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const newTask = {
      name: taskName,
      completed: false,
      priority: taskPriority,
      time: taskTime,
    };
    addTask(day, newTask);
    setTaskName("");
    setTaskTime("");
  };

  return (
    <form onSubmit={handleSubmit} className="task-form vertical-form">
      <label>
        Task Name:
        <input
          type="text"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          required
        />
      </label>

      <label>
        Day of the Week:
        <select value={day} onChange={(e) => setDay(e.target.value)} required>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
          <option value="Sunday">Sunday</option>
        </select>
      </label>

      <label>
        Priority:
        <select value={taskPriority} onChange={(e) => setTaskPriority(e.target.value)} required>
          <option value="Low">Low</option>
          <option value="Normal">Normal</option>
          <option value="High">High</option>
        </select>
      </label>

      <label>
        Time:
        <input
          type="time"
          value={taskTime}
          onChange={(e) => setTaskTime(e.target.value)}
          required
        />
      </label>

      <button type="submit" className="submit-btn">Add Task</button>
    </form>
  );
};

export default TaskForm;
